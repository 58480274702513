import React, { useEffect, useLayoutEffect, useRef, useState, useContext, createContext, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { start } from 'repl';
import { stringify } from 'querystring';

export const Controller = () => {
    const [connection, setConnection] = useState<null | HubConnection>(null);
    const navigate = useNavigate();

    // Builds the SignalR connection, mapping it to /hub
    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl("/hub")
            //.withUrl("https://localhost:44389/hub")
            .withAutomaticReconnect()
            .build();

        setConnection(connect);

    }, []);

    const getLocalRtc = () => {
        let rtc = localStorage.getItem('runtimeClock');
        if (rtc != null) {
            let parseRtc = JSON.parse(rtc);
            let finalRtc = parseRtc.time;
            return finalRtc;
        } else {
            return 0;
        }
    }

    const getLocalEtr = () => {
        let ert = localStorage.getItem('estimatedTimeRemainingClock');
        if (ert != null) {
            let parseErt = JSON.parse(ert);
            let finalErt = parseErt.time;
            return finalErt;
        } else {
            return 0;
        }
    }

    const setReset = () => {
        localStorage.setItem('runtimeClock', JSON.stringify({ time: 0 }));
        localStorage.setItem('estimatedTimeRemainingClock', JSON.stringify({ time: 0 }));
    }

    const handleStart = async() => {
        await connection?.invoke("StartRollCallTimer").then(() => {
            let obj = {
                start: true,
                pause: false,
                reset: false,
                rtc: getLocalRtc(),
                etr: getLocalEtr()
            }
            localStorage.setItem("timerState", JSON.stringify(obj));
        })
        //navigate("/", { state: { start: true, pause: false, reset: false, rtc: getLocalRtc(), etr: getLocalEtr() } })
    }

    const handlePause = async() => {
        await connection?.invoke("StopRollCallTimer").then(() => {
            let obj = {
                start: false,
                pause: true,
                reset: false,
                rtc: getLocalRtc(),
                etr: getLocalEtr()
            }
            localStorage.setItem("timerState", JSON.stringify(obj));
        })
        //navigate("/", { state: { start: false, pause: true, reset: false, rtc: getLocalRtc(), etr: getLocalEtr() } });
    }

    const handleReset = async() => {
        setReset();
        await connection?.invoke("ResetRollCallTimer").then(() => {
            let obj = {
                start: false,
                pause: false,
                reset: true,
                rtc: getLocalRtc(),
                etr: getLocalEtr()
            }
            localStorage.setItem("timerState", JSON.stringify(obj));
        })
        //navigate("/", { state: { start: false, pause: true, reset: true, rtc: 0, etr: 0 } });
    }

    const handleConnect = () => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    console.log('connected');
                })
        }
    }

    const handleClick = async (eventKey: string | null, event: any) => {
        event.persist();
        const response = await fetch("ceremony", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                CeremonyId: eventKey
            })
        });
        const data = await response.json();
        console.log(data);
    }

    const [show, setShow] = useState(false);
    const ceremonies = ["Revelle College Commencement", "John Muir College Commencement",
        "Thurgood Marshall College Commencement", "Earl Warren College Commencement",
        "Eleanor Roosevelt College Commencement", "Eleanor Roosevelt College Commencement"];
    return (
        <div id="buttons-wrap">
            <Button variant="primary" onClick={handleConnect} className="connect-button">Connect</Button>
            <Button variant="success" onClick={handleStart} className="start-button">Start</Button>
            <Button variant="warning" onClick={handlePause} className="pause-button">Pause</Button>
            <Button variant="danger" onClick={handleReset} className="reset-button">Clear</Button>
            <Dropdown onSelect={handleClick}>
                <Dropdown.Toggle>
                    Current Ceremony
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item eventKey="4562396">
                        Revelle College Commencement
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4562397">
                        John Muir College Commencement
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4562398">
                        Thurgood Marshall College Commencement
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4562399">
                        Earl Warren College Commencement
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4562400">
                        Eleanor Roosevelt College Commencement
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="4562401">
                        Sixth College Commencement
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );


}

