import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

interface IProps{
    children: any;
}
export class Layout extends Component<IProps> {
    static displayName = Layout.name;

    render() {
        return (
            //page animations should happen here
            <div>
                <NavMenu />
                <Container>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}