import React, { useEffect, useLayoutEffect, useRef, useState, useContext, createContext } from 'react';
//import { useNavigate, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { start } from 'repl';
//import RunTimeClock from './RunTimeClock';
import EstimatedRemaining from './EstimatedRemaining';
//import CadenceTrack from './CadenceTrack';

interface Props {
    currentTarget: any;
}

interface IHome {
    checkedInCount: number,
    timeStampedCount: number,
    timeRemaining: number,
    estimatedEndTime: string,
    cadence: string,
    state: number,
    numberRemaining: number,
    percentRemaining: number,
    ceremony: string
}

interface IRunTimeClock { 
    runtime: string
}
interface TimerState {
    start: boolean,
    pause: boolean,
    reset: boolean,
    rtc: number,
    etr: number
}

const getSwoogoCounts = async (url: string): Promise<string> => {
    const response = await fetch(url);
    const data = await response.json();
    return data;
}

export const Home = () => {
    const [connection, setConnection] = useState<null | HubConnection>(null);
    const [inputText, setInputText] = useState("");

    const [timerState, setTimerState] = React.useState<TimerState>({        
        start: false,
        pause: true,
        reset: false,
        rtc: 0,
        etr: 0
    })
    //handle data from controller
    const setWebhookData = () => {
        let timerStateStr = localStorage.getItem('timerState');
        let timerStateObj;
        if (timerStateStr != null) {
            timerStateObj = JSON.parse(timerStateStr);
        }

        /*setTimerState({
            start: timerStateObj.start,
        })*/
        if (timerStateObj != null) {
            setTimerState({
                start: timerStateObj.start,
                pause: timerStateObj.pause,
                reset: timerStateObj.reset,
                rtc: timerStateObj.rtc,
                etr: timerStateObj.etr
            })
        }
    }

    connection?.on("start", () => {
        setWebhookData();
    })

    connection?.on("stop", () => {
        setWebhookData();
    })

    connection?.on("reset", () => {
        setWebhookData();
    })

    // Builds the SignalR connection, mapping it to /hub
    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl("/hub") //PROD
            //.withUrl("https://localhost:44389/hub")
            .withAutomaticReconnect()
            .build();

        setConnection(connect);
    }, []);

    const GetCounts = () => {
        // Sets the time from the server
        //const [counts, setCounts] = useState<[checkedInCount: string, timeStampedCount: string] | null>(null);
        const [ihome, setIHome] = React.useState<IHome>({
            checkedInCount: 0,
            timeStampedCount: 0,
            timeRemaining: 0,
            estimatedEndTime: '',
            cadence: '',
            state: 0,
            numberRemaining: 0,
            percentRemaining: 0,
            ceremony: "John Muir"
        });

        const [runtimeClock, setRunTime] = React.useState<IRunTimeClock>({
            runtime: ""
        });

        useEffect(() => {
            if (connection) {
                connection
                    .start()
                    .then(async () => {
                        connection.on("setState", (message) => {
                            console.log(message);

                            //////////////////////////////////EET & ETR
                            function addSeconds(date: Date, seconds: number) {
                                let dateCopy = new Date(date);//will hold the EED
                                dateCopy.setSeconds(date.getSeconds() + seconds);

                                return dateCopy;
                            }

                            //CALCULATE ETR CLOCK
                            let etr = (message.checkedInCount - message.currentTimeStampedCount) / message.cadence * 60;
                            if (etr < 0) {
                                etr = 0;
                            }

                            let today = new Date();
                            let newDate = addSeconds(today, etr);
                            //console.log(newDate);
                            let etaHrs = newDate.getHours();
                            let etaMin = newDate.getMinutes();
                            let meridiemIndicator: string;
                            let estimatedEndTime: string;

                            if (etaHrs > 11) {
                                if (etaHrs == 12) {
                                    meridiemIndicator = 'PM';
                                } else {
                                    etaHrs = etaHrs - 12;
                                    meridiemIndicator = 'PM';
                                }
                            } else {
                                meridiemIndicator = 'AM';
                            }

                            if (message.cadence > 0) {
                                if (etaMin < 10) {
                                    estimatedEndTime = etaHrs + ':0' + etaMin + ' ' + meridiemIndicator;
                                } else {
                                    estimatedEndTime = etaHrs + ':' + etaMin + ' ' + meridiemIndicator;
                                }
                            } else {
                                estimatedEndTime = " - ";
                            }

                            console.log(etr);

                            if (message.state == 0) {
                                message.state = 0;
                            }
                            var Ceremony = "John Muir";

                            switch (message.CeremonyId) {
                                case "4562396":
                                    Ceremony = "Revelle College Commencement"
                                    break;
                                case "4562397":
                                    Ceremony = "John Muir College Commencement"
                                    break;
                                case "4562398":
                                    Ceremony = "Thurgood Marshall College Commencement"
                                    break;
                                case "4562399":
                                    Ceremony = "Earl Warren College Commencement"
                                    break;
                                case "4562400":
                                    Ceremony = "Eleanor Roosevelt College Commencement"
                                    break;
                                case "4562401":
                                    Ceremony = "Sixth College Commencement"
                                    break;
                            }
                            let numRemaining = message.checkedInCount - message.currentTimeStampedCount;
                            let perRem = numRemaining / message.checkedInCount * 100;
                            perRem = parseFloat(perRem.toPrecision(4))
                            setIHome({
                                checkedInCount: message.checkedInCount,
                                timeStampedCount: message.currentTimeStampedCount,
                                timeRemaining: etr,
                                estimatedEndTime: estimatedEndTime,
                                cadence: message.cadence,
                                state: message.state,
                                numberRemaining: numRemaining,
                                percentRemaining: perRem,
                                ceremony: Ceremony
                            });
                        });
                        //this will get the values of the counts after the initial values
                        connection.on("setTime", (message) => {
                            setRunTime({
                                runtime: message
                            })
                        });
                        
                    })
                    .catch((error) => console.log(error));
            }
        }, [connection]);


        //console.log(ihome);

        return (
            <div id="timersWrap">
                <EstimatedRemaining time={ihome.timeRemaining} appState={ihome.state}/*localEtr={timerState.etr} start={timerState.start} pause={timerState.pause} reset={timerState.reset}*/ key={ihome.estimatedEndTime} />
                <hr />
                <div className="componentTimer">Estimated End Time: {ihome.estimatedEndTime}</div>
                <div id="runTimeClockWrap">
                    <div className="componentLabel">Current Run Time:&nbsp;</div><div id="runTimeDisplay" className="componentTimer">{runtimeClock.runtime}</div>
                </div>
                <div id="cadenceTrackWrap">
                    <div className="componentLabel">Scan Frequency:&nbsp;</div><div id="cadenceDisplay" className="componentTimer">{ihome.cadence} Students / Minute</div>
                </div>
                <div className="numbers">
                    <div className="componentLabel">Checked In:&nbsp;</div><div id="checkedInDisplay" className="componentTimer">{ihome.checkedInCount}</div>          
                </div>
                <div className="numbers">
                    <div className="componentLabel">Time Stamped:&nbsp;</div><div id="stampedDisplay" className="componentTimer">{ihome.timeStampedCount}</div>
                </div>

                <div className="numbers">
                    <div className="componentLabel">Number Remaining:&nbsp;</div><div id="remainingDisplay" className="componentTimer">{ihome.numberRemaining}</div>
                </div>
                <div className="numbers">
                    <div className="componentLabel">Percent Remaining:&nbsp;</div><div id="percentDisplay" className="componentTimer">{ihome.percentRemaining}%</div>
                </div>

            </div>
        );

    };

    /**
     * Roll Call Start time should be persisted
     * Estimated end time will update every x minutes based on data returned from swoogo
     * Estimated Remaining Run Time is the remaining students * (delta students / delta time). 
     * i.e. 5 students remaining and cadence is 5 students / minute
     * Student Cadence
     * */
    return (
        <div className="app">          
            <GetCounts></GetCounts>
        </div>
    );

    
}



/* {
    //setCounts(message)
    let prevTimeStamped = ihome.timeStampedCount;//time stamped students from previous check TYPE STRING
    let prevTimeStampedInt: number; //time stamped students from previous check TYPE NUMBER
    let checkedIn = parseInt(message.checkedInCount);//total attending student count
    let timeStamped = parseInt(message.timeStampedCount);//current time stamp students
    ///////////////////////////////////CALCULATE CADENCE (SCAN FREQUENCY)///////////////////////////////////
    if (prevTimeStamped == '') {
        prevTimeStamped = '';
    }
    prevTimeStampedInt = parseInt(prevTimeStamped);
    let cadence = timeStamped - prevTimeStampedInt;//students per minute scanned
    ///////////////////////////////////CALCULATE EET (ESTIMATED END TIME)///////////////////////////////////

    function addSeconds(date: Date, seconds: number) {
        let dateCopy = new Date(date);//will hold the EED
        dateCopy.setSeconds(date.getSeconds() + seconds);

        return dateCopy;
    }

    //CALCULATE ETR CLOCK
    let etr = (checkedIn - timeStamped) / cadence * 60; //240 or 4min
    if (etr < 0) {
        etr = 0;
    }

    let today = new Date();
    let newDate = addSeconds(today, etr);
    //console.log(newDate);
    let etaHrs = newDate.getHours();
    let etaMin = newDate.getMinutes();
    let meridiemIndicator: string;
    let estimatedEndTime: string;

    if (etaHrs > 11) {
        if (etaHrs == 12) {
            meridiemIndicator = 'PM';
        } else {
            etaHrs = etaHrs - 12;
            meridiemIndicator = 'PM';
        }
    } else {
        meridiemIndicator = 'AM';
    }

    if (etaMin < 10) {
        estimatedEndTime = etaHrs + ':0' + etaMin + ' ' + meridiemIndicator;
    } else {
        estimatedEndTime = etaHrs + ':' + etaMin + ' ' + meridiemIndicator;
    }

    //var etrSec = (newDate.getTime() - today.getTime()) / 1000;
    //console.log(etrSec);


    //console.log('newDate ' + newDate); // 2022-04-17T00:00:20.000Z

    // Original not modified
    //console.log('today '+today); // 2022-04-17T00:00:00.000Z


    setIHome({
        checkedInCount: checkedIn.toString(),
        timeStampedCount: message.timeStampedCount,
        timeRemaining: etr,
        estimatedEndTime: estimatedEndTime,
        cadence: cadence.toString()
    });
}*/

    //const location = useLocation();

/*let localStateStart:boolean, localStatePause:boolean, localStateReset: boolean, localStateRtc: number, localStateEtr: number;
 
if (location.state != null) {
    localStateStart = location.state.start;
    localStatePause = location.state.pause;
    localStateReset = location.state.reset;
    localStateRtc = location.state.rtc;
    localStateEtr = location.state.etr;
} else {
    localStateStart = false;
    localStatePause = true;
    localStateReset = false;
    localStateRtc = 0;
    localStateEtr = 0;
}
*/

/*
                <div className="numbers">
                    <div className="componentLabel">Current Ceremony:&nbsp;</div><div id="percentDisplay" className="componentTimer">{ihome.ceremony}</div>
                </div>
*/ 