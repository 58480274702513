import React from 'react';

interface State {
    time: number;
    seconds: number;
    minutes: number;
    hours: number;
}

interface Props {
    time: number,
    appState: number
    /*localEtr: number,
    start: boolean,
    pause: boolean,
    reset: boolean*/
}

const EstimatedRemaining: React.FC<Props> = ({ time, appState/*, localEtr, start, pause, reset*/ }) => {

    let Hrs = time / 3600;
    let rdHrs = Math.floor(Hrs);
    let Min = (Hrs - rdHrs) * 60;
    let rdMin = Math.floor(Min);
    let Sec = (Min - rdMin) * 60;
    let rdSec = Math.floor(Sec);
    
    const [state, setState] = React.useState<State>({
        time:time,
        hours: rdHrs,
        minutes: rdMin,
        seconds: rdSec
    });


    React.useEffect(() => {
        setTimeout(() => {
            /*if (state.time === 0) {
                return;
            }*/

            /*if (pause === true || reset === true) {
                let newTime = localEtr;
                let newHrs = newTime / 3600;
                let newRdHrs = Math.floor(newHrs);
                let newMin = (newHrs - newRdHrs) * 60;
                let newRdMin = Math.floor(newMin);
                let newSec = (newMin - newRdMin) * 60;
                let newRdSec = Math.floor(newSec);

                setState({
                    time: newTime,
                    seconds: newRdSec,
                    minutes: newRdMin,
                    hours: newRdHrs
                });

                return;
            }*/

            if (time > 0 && time != Infinity) {
                /*let newTime = 0;

                switch (appState) {
                    case 0:
                        newTime = 0;
                        break;
                    case 1:
                        newTime = state.time - 1;
                        break;
                    case 2:
                        newTime = state.time;
                        break;
                }*/
                let newTime = state.time - 1;
                let newHrs = newTime / 3600;
                let newRdHrs = Math.floor(newHrs);
                let newMin = (newHrs - newRdHrs) * 60;
                let newRdMin = Math.floor(newMin);
                let newSec = (newMin - newRdMin) * 60;
                let newRdSec = Math.floor(newSec);
                //console.log(newTime);
                setState({
                    time: newTime,
                    seconds: newRdSec,
                    minutes: newRdMin,
                    hours: newRdHrs
                });

                //localStorage.setItem('estimatedTimeRemainingClock', JSON.stringify({ time: newTime }));
            } else {
                setState({
                    time: 0,
                    seconds: 0,
                    minutes: 0,
                    hours: 0
                })
            }
        }, 1000);
    }, [state.time/*, localEtr*/]);

    return (
        <div id="estimatedRemainingWrap">
            <div id="estimatedRemainingDisplay">
                <span className="componentLabel">Estimated Remaining Time:</span><br /><span className="componentTimer">{`${state.hours < 10 ? `0${state.hours}` : state.hours}:${state.minutes < 10 ? `0${state.minutes}` : state.minutes}:${state.seconds < 10 ? `0${state.seconds}` : state.seconds}`}</span></div>
        </div>
    );
};

export default EstimatedRemaining;