import { Home } from "./components/Home";
import { Controller } from "./components/Controller";
const AppRoutes = [
  {
    index: true,
    element: <Home />
    },
    {
        path: "/controller",
        element: <Controller />
    }
];

export default AppRoutes;
